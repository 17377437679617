import logo from './logomark.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Anglin Industries
        </h1>
        <a
          className="App-link"
          href="tel:+14052044758"
        >
          +1 (405) 204 4758
        </a>
        <p>
          Oklahoma City, OK
        </p>
      </header>
    </div>
  );
}

export default App;
